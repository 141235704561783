<template>
	<div>
		<div class="simple-title">Contact Us<span class="fal fa-envelope"></span></div>
		<div class="form-wrapper small">

			<div v-if="sent" class="alert alert-good">
				<ul>
					<li><span class="fal fa-thumbs-up"></span>Thank you for your inquiry. We'll respond as soon as possible.</li>
				</ul>
			</div>

			<form-errors :errors="errors"></form-errors>

			<form id="inquiry-form" action method="post" @submit.prevent="onSubmit">
				<field-errors v-if="submitAttempted" :form="$v.form" field="name"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Your Name</div>
					<div class="input-box"><input v-model="form.name" name="name" tabindex="1" type="text"></div>
				</div>

				<field-errors v-if="submitAttempted" :form="$v.form" field="email"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">E-mail Address</div>
					<div class="input-box"><input v-model="form.email" name="email" tabindex="2" type="text"></div>
				</div>

				<field-errors v-if="submitAttempted" :form="$v.form" field="phone"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Phone Number</div>
					<div class="input-box"><input v-model="form.phone" name="phone" tabindex="3" type="text"></div>
				</div>

				<input name="subject" tabindex="6" type="text">

				<field-errors v-if="submitAttempted" :form="$v.form" field="message"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Message</div>
					<div class="input-box"><textarea v-model="form.message" name="message" tabindex="4"></textarea>
					</div>
				</div>

				<submit-button :has-errors="hasErrors" :loading="formSaving" icon="fa-envelope" text="Send Inquiry"></submit-button>
			</form>
		</div>
	</div>
</template>

<script>

import { email, required } from 'vuelidate/lib/validators';
import FormMixin from '@/mixins/FormMixin';

export default {

	name: 'ContactPage',

	mixins: [FormMixin],

	data() {
		return {
			errors: [],
			form: {},
			sent: false,
		};
	},

	validations: {
		form: {
			name: {
				required,
			},
			phone: {
				required,
			},
			message: {
				required,
			},
			email: {
				required,
				email,
			},
		},
	},

	methods: {

		async recaptcha() {
			// (optional) Wait until recaptcha has been loaded.
			await this.$recaptchaLoaded();

			// Execute reCAPTCHA with action "contact".
			this.form.recaptcha_token = await this.$recaptcha('contact');
		},

		async onSubmit() {
			this.sent = false;
			await this.submit(async () => {
				try {
					await this.recaptcha();
					await this.$api.get('/sanctum/csrf-cookie');
				} catch(e) {
					this.$store.dispatch('errorToast', 'Unexpected error encountered');
				}

				await this.$api.post('/contact/inquiry', this.form);
				this.sent = true;
				this.form = {};
				this.submitAttempted = false;
			});
		},
	},

};

</script>

<style>
input[name="subject"] {
	outline: none !important;
	background: transparent !important;
	color: transparent !important;
	border: none !important;
	display: block !important;
	cursor: default !important;
	padding: 0px !important;
	margin: 0px !important;
	margin-top: -10px !important;
	height: 10px !important;
}
</style>
